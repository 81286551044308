import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/plugins/elementor/lib/eicons/css/elementor-icons.min.css'
import '@/assets/plugins/elementor/lib/animations/animations.min.css'
import '@/assets/plugins/elementor/css/frontend-legacy.min.css'
import '@/assets/elementor/css/custom-frontend.min.css'
import '@/assets/elementor/css/post-16.css'
import '@/assets/elementor/css/custom-pro-frontend.min.css'
import '@/assets/plugins/elementor/lib/font-awesome/css/all.min.css'
import '@/assets/plugins/elementor/lib/font-awesome/css/v4-shims.min.css'
import '@/assets/css/slick.css'
import '@/assets/css/slick-theme.css'
import '@/assets/plugins/elementor/lib/slick/slick.min.js'
import '@/assets/css/style.css'
import '@/assets/elementor/css/post-14.css'
import '@/assets/elementor/css/post-22.css'
import '@/assets/js/style.min.js'
import '@/assets/js/wp-embed.min.js'
import '@/assets/plugins/elementor-pro/lib/smartmenus/jquery.smartmenus.min.js'
import '@/assets/js/imagesloaded.min.js'
import '@/assets/plugins/elementor-pro/js/webpack-pro.runtime.min.js'
import '@/assets/plugins/elementor/js/webpack.runtime.min.js'
import '@/assets/elementor/js/frontend-modules.min.js'
import '@/assets/plugins/elementor-pro/lib/sticky/jquery.sticky.min.js'
import '@/assets/plugins/elementor/lib/waypoints/waypoints.min.js'
import '@/assets/plugins/elementor/lib/swiper/swiper.min.js'
import '@/assets/plugins/elementor/lib/share-link/share-link.min.js'
import '@/assets/plugins/elementor/lib/dialog/dialog.min.js'
import '@/assets/plugins/elementor/js/frontend.min.js'
import '@/assets/plugins/elementor-pro/js/frontend.min.js'
import '@/assets/plugins/elementor-pro/js/preloaded-elements-handlers.min.js'
import '@/assets/elementor/js/preloaded-modules.min.js'


createApp(App).use(router).mount('#app')

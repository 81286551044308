import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
    meta: {
      title: 'Homepage - American Investment Council'
    }
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('../views/about-us.vue'),
    meta: {
      title: 'Private Equity Investments - Activities'
    }
  },
  {
    path: '/portfolio',
    name: 'portfolio-category',
    redirect: '/portfolio/fund-i',
    children: [
      {
        path: 'fund-i',
        name: 'fund-i',
        component: () => import('../views/portfolio-category/fundI.vue'),
        meta: {
          title: 'Fund I Archives - Kedaara'
        }
      },
      {
        path: 'fund-ii',
        name: 'fund-ii',
        component: () => import('../views/portfolio-category/fundII.vue'),
        meta: {
          title: 'Fund II Archives - Kedaara'
        }
      },
      {
        path: 'fund-iii',
        name: 'fund-iii',
        component: () => import('../views/portfolio-category/fundIII.vue'),
        meta: {
          title: 'Fund III Archives - Kedaara'
        }
      },
      {
        path: 'mahindra-logistics',
        name: 'mahindra-logistics',
        component: () => import('../views/portfolio/mahindra-logistics.vue'),
        meta: {
          title: 'Mahindra Logistics - Kedaara'
        }
      },
      {
        path: 'au-small-finance-bank',
        name: 'au-small-finance-bank',
        component: () => import('../views/portfolio/au-small-finance-bank.vue'),
        meta: {
          title: 'Au Small Finance Bank - Kedaara'
        }
      },
      {
        path: 'parksons-packaging',
        name: 'parksons-packaging',
        component: () => import('../views/portfolio/parksons-packaging.vue'),
        meta: {
          title: 'Parksons Packaging - Kedaara'
        }
      },
      {
        path: 'manjushree-technopack',
        name: 'manjushree-technopack',
        component: () => import('../views/portfolio/manjushree-technopack.vue'),
        meta: {
          title: 'Manjushree Technopack - Kedaara'
        }
      },
      {
        path: 'bill-forge',
        name: 'bill-forge',
        component: () => import('../views/portfolio/bill-forge.vue'),
        meta: {
          title: 'Bill Forge - Kedaara'
        }
      },
      {
        path: 'aavas-financiers',
        name: 'aavas-financiers',
        component: () => import('../views/portfolio/aavas-financiers.vue'),
        meta: {
          title: 'Aavas Financiers - Kedaara'
        }
      },
      {
        path: 'vijaya-diagnostics',
        name: 'vijaya-diagnostics',
        component: () => import('../views/portfolio/vijaya-diagnostics.vue'),
        meta: {
          title: 'Vijaya Diagnostics - Kedaara'
        }
      },
      {
        path: 'vedant-fashions-manyavar-mohey',
        name: 'vedant-fashions-manyavar-mohey',
        component: () => import('../views/portfolio/vedant-fashions-manyavar-mohey.vue'),
        meta: {
          title: 'Vedant Fashions (Manyavar & Mohey) - Kedaara'
        }
      },
      {
        path: 'spandana-sphoorty',
        name: 'spandana-sphoorty',
        component: () => import('../views/portfolio/spandana-sphoorty.vue'),
        meta: {
          title: 'Spandana Sphoorty - Kedaara'
        }
      },
      {
        path: 'sunbeam-lightweighting-solutions',
        name: 'sunbeam-lightweighting-solutions',
        component: () => import('../views/portfolio/sunbeam-lightweighting-solutions.vue'),
        meta: {
          title: 'Sunbeam Lightweighting Solutions - Kedaara'
        }
      },
      {
        path: 'vishal-megamart',
        name: 'vishal-megamart',
        component: () => import('../views/portfolio/vishal-megamart.vue'),
        meta: {
          title: 'Vishal Megamart - Kedaara'
        }
      },
      {
        path: 'ajax',
        name: 'ajax',
        component: () => import('../views/portfolio/ajax.vue'),
        meta: {
          title: 'Ajax - Kedaara'
        }
      },
      {
        path: 'lenskart',
        name: 'lenskart',
        component: () => import('../views/portfolio/lenskart.vue'),
        meta: {
          title: 'Lenskart - Kedaara'
        }
      },
      {
        path: 'ami-lifesciences',
        name: 'ami-lifesciences',
        component: () => import('../views/portfolio/ami-lifesciences.vue'),
        meta: {
          title: 'Ami Lifesciences - Kedaara'
        }
      },
      {
        path: 'veritas',
        name: 'veritas',
        component: () => import('../views/portfolio/veritas.vue'),
        meta: {
          title: 'Veritas - Kedaara'
        }
      },
      {
        path: 'care-health',
        name: 'care-health',
        component: () => import('../views/portfolio/care-health.vue'),
        meta: {
          title: 'Care Health - Kedaara'
        }
      },
      {
        path: 'universal-nutriscience',
        name: 'universal-nutriscience',
        component: () => import('../views/portfolio/universal-nutriscience.vue'),
        meta: {
          title: 'Universal NutriScience - Kedaara'
        }
      },
      {
        path: 'gs-lab',
        name: 'gs-lab',
        component: () => import('../views/portfolio/gs-lab.vue'),
        meta: {
          title: 'GS Lab - Kedaara'
        }
      },
      {
        path: 'purplle',
        name: 'purplle',
        component: () => import('../views/portfolio/purplle.vue'),
        meta: {
          title: 'Purplle - Kedaara'
        }
      },
      {
        path: 'gavs',
        name: 'gavs',
        component: () => import('../views/portfolio/gavs.vue'),
        meta: {
          title: 'GAVS - Kedaara'
        }
      },
      {
        path: 'spandana-sphoorty-2',
        name: 'spandana-sphoorty-2',
        component: () => import('../views/portfolio/spandana-sphoorty-2.vue'),
        meta: {
          title: 'Spandana Sphoorty - Kedaara'
        }
      },
      {
        path: 'asg-eye-hospital',
        name: 'asg-eye-hospital',
        component: () => import('../views/portfolio/asg-eye-hospital.vue'),
        meta: {
          title: 'ASG Eye Hospital - Kedaara'
        }
      },
      {
        path: 'oasis-fertility',
        name: 'oasis-fertility',
        component: () => import('../views/portfolio/oasis-fertility.vue'),
        meta: {
          title: 'Oasis Fertility - Kedaara'
        }
      },
      {
        path: 'avanse',
        name: 'avanse',
        component: () => import('../views/portfolio/avanse.vue'),
        meta: {
          title: 'Avanse - Kedaara'
        }
      },
      {
        path: 'oliva-skin-hair-clinic',
        name: 'oliva-skin-hair-clinic',
        component: () => import('../views/portfolio/oliva-skin-hair-clinic.vue'),
        meta: {
          title: 'Oliva Skin & Hair Clinic - Kedaara'
        }
      },
      {
        path: 'k12-techno-services',
        name: 'k12-techno-services',
        component: () => import('../views/portfolio/k12-techno-services.vue'),
        meta: {
          title: 'K12 Techno Services - Kedaara'
        }
      },
      {
        path: 'perfios',
        name: 'perfios',
        component: () => import('../views/portfolio/perfios.vue'),
        meta: {
          title: 'Perfios - Kedaara'
        }
      },
      {
        path: 'lenskart-2',
        name: 'lenskart-2',
        component: () => import('../views/portfolio/lenskart-2.vue'),
        meta: {
          title: 'Lenskart - Kedaara'
        }
      },
      {
        path: 'dairy-day',
        name: 'dairy-day',
        component: () => import('../views/portfolio/dairy-day.vue'),
        meta: {
          title: 'Dairy Day - Kedaara'
        }
      },
    ]
  },
  {
    path: '/team',
    name: 'team',
    redirect: '/teamgroup/investment-team'
  },
  {
    path: '/press-release',
    name: 'press-release',
    component: () => import('../views/pressRelease.vue'),
    meta: {
      title: 'Press Release - Kedaara'
    }
  },
  {
    path: '/teamgroup',
    name: 'teamgroup',
    redirect: '/teamgroup/investment-team',
    children: [
      {
        path: 'investment-team',
        name: 'investment-team',
        component: () => import('../views/teamgroup/investmentTeam.vue'),
        meta: {
          title: 'Investment Team Archives - Kedaara'
        }
      },
      {
        path: 'operating-team',
        name: 'operating-team',
        component: () => import('../views/teamgroup/operatingTeam.vue'),
        meta: {
          title: 'Operating Team Archives - Kedaara'
        }
      },
      {
        path: 'board-of-directors',
        name: 'board-of-directors',
        component: () => import('../views/teamgroup/boardOfDirectors.vue'),
        meta: {
          title: 'Board of Directors Archives - Kedaara'
        }
      },
    ]
  },
  {
    path: '/team-member',
    name: 'team-member',
    redirect: '/team-member/manish-kejriwal',
    children: [
      {
        path: 'manish-kejriwal',
        name: 'manish-kejriwal',
        component: () => import('../views/team-member/manish-kejriwal.vue'),
        meta: {
          title: 'Manish Kejriwal - Kedaara'
        }
      },
      {
        path: 'sunish-sharma',
        name: 'sunish-sharma',
        component: () => import('../views/team-member/sunish-sharma.vue'),
        meta: {
          title: 'Sunish Sharma - Kedaara'
        }
      },
      {
        path: 'nishant-sharma',
        name: 'nishant-sharma',
        component: () => import('../views/team-member/nishant-sharma.vue'),
        meta: {
          title: 'Nishant Sharma - Kedaara'
        }
      },
      {
        path: 'anant-gupta',
        name: 'anant-gupta',
        component: () => import('../views/team-member/anant-gupta.vue'),
        meta: {
          title: 'Anant Gupta - Kedaara'
        }
      },
      {
        path: 'kartikeya-kaji',
        name: 'kartikeya-kaji',
        component: () => import('../views/team-member/kartikeya-kaji.vue'),
        meta: {
          title: 'Kartikeya Kaji - Kedaara'
        }
      },
      {
        path: 'ashay-shah',
        name: 'ashay-shah',
        component: () => import('../views/team-member/ashay-shah.vue'),
        meta: {
          title: 'Ashay Shah - Kedaara'
        }
      },
      {
        path: 'ashutosh-sardesai',
        name: 'ashutosh-sardesai',
        component: () => import('../views/team-member/ashutosh-sardesai.vue'),
        meta: {
          title: 'Ashutosh Sardesai - Kedaara'
        }
      },
      {
        path: 'ashwit-mahajan',
        name: 'ashwit-mahajan',
        component: () => import('../views/team-member/ashwit-mahajan.vue'),
        meta: {
          title: 'Aashwit Mahajan - Kedaara'
        }
      },
      {
        path: 'rahul-mehta',
        name: 'rahul-mehta',
        component: () => import('../views/team-member/rahul-mehta.vue'),
        meta: {
          title: 'Rahul Mehta - Kedaara'
        }
      },
      {
        path: 'kshama-jain',
        name: 'kshama-jain',
        component: () => import('../views/team-member/kshama-jain.vue'),
        meta: {
          title: 'Kshama Jain - Kedaara'
        }
      },
      {
        path: 'nishant-naveen',
        name: 'nishant-naveen',
        component: () => import('../views/team-member/nishant-naveen.vue'),
        meta: {
          title: 'Nishant Naveen - Kedaara'
        }
      },
      {
        path: 'kartikey-gupta',
        name: 'kartikey-gupta',
        component: () => import('../views/team-member/kartikey-gupta.vue'),
        meta: {
          title: 'Kartikey Gupta - Kedaara'
        }
      },
      {
        path: 'vidur-suri',
        name: 'vidur-suri',
        component: () => import('../views/team-member/vidur-suri.vue'),
        meta: {
          title: 'Vidur Suri - Kedaara'
        }
      },
      {
        path: 'saurabh-pinjani',
        name: 'saurabh-pinjani',
        component: () => import('../views/team-member/saurabh-pinjani.vue'),
        meta: {
          title: 'Saurabh Pinjani - Kedaara'
        }
      },
      {
        path: 'vikas-kumar',
        name: 'vikas-kumar',
        component: () => import('../views/team-member/vikas-kumar.vue'),
        meta: {
          title: 'Vikas Kumar - Kedaara'
        }
      },
      {
        path: 'purav-shah',
        name: 'purav-shah',
        component: () => import('../views/team-member/purav-shah.vue'),
        meta: {
          title: 'Purav Shah - Kedaara'
        }
      },
      {
        path: 'rutuj-shah',
        name: 'rutuj-shah',
        component: () => import('../views/team-member/rutuj-shah.vue'),
        meta: {
          title: 'Rutuj Shah - Kedaara'
        }
      },
      {
        path: 'aditya-kulkarni',
        name: 'aditya-kulkarni',
        component: () => import('../views/team-member/aditya-kulkarni.vue'),
        meta: {
          title: 'Aditya Kulkarni - Kedaara'
        }
      },
      {
        path: 'sakshi-gera',
        name: 'sakshi-gera',
        component: () => import('../views/team-member/sakshi-gera.vue'),
        meta: {
          title: 'Saakshi Gera - Kedaara'
        }
      },
      {
        path: 'mudit-agrawal',
        name: 'mudit-agrawal',
        component: () => import('../views/team-member/mudit-agrawal.vue'),
        meta: {
          title: 'Mudit Agrawal - Kedaara'
        }
      },
      {
        path: 'parin-mehta',
        name: 'parin-mehta',
        component: () => import('../views/team-member/parin-mehta.vue'),
        meta: {
          title: 'Parin Mehta - Kedaara'
        }
      },
      {
        path: 'vedaant-sikka',
        name: 'vedaant-sikka',
        component: () => import('../views/team-member/vedaant-sikka.vue'),
        meta: {
          title: 'Vedaant Sikka - Kedaara'
        }
      },
      {
        path: 'kabir-narula',
        name: 'kabir-narula',
        component: () => import('../views/team-member/kabir-narula.vue'),
        meta: {
          title: 'Kabir Narula - Kedaara'
        }
      },
      {
        path: 'nikita-dipali',
        name: 'nikita-dipali',
        component: () => import('../views/team-member/nikita-dipali.vue'),
        meta: {
          title: 'Nikita Dipali - Kedaara'
        }
      },
      {
        path: 'pranjal-singh',
        name: 'pranjal-singh',
        component: () => import('../views/team-member/pranjal-singh.vue'),
        meta: {
          title: 'Pranjal Singh - Kedaara'
        }
      },
      {
        path: 'sanjeev-aga',
        name: 'sanjeev-aga',
        component: () => import('../views/team-member/sanjeev-aga.vue'),
        meta: {
          title: 'Sanjeev Aga - Kedaara'
        }
      },
      {
        path: 'pramod-bhasin',
        name: 'pramod-bhasin',
        component: () => import('../views/team-member/pramod-bhasin.vue'),
        meta: {
          title: 'Pramod Bhasin - Kedaara'
        }
      },
      {
        path: 'aditya-vij',
        name: 'aditya-vij',
        component: () => import('../views/team-member/aditya-vij.vue'),
        meta: {
          title: 'Aditya Vij - Kedaara'
        }
      },
      {
        path: 'k-r-kamath',
        name: 'k-r-kamath',
        component: () => import('../views/team-member/k-r-kamath.vue'),
        meta: {
          title: 'K.R. Kamath - Kedaara'
        }
      },
      {
        path: 'neeraj-swaroop',
        name: 'neeraj-swaroop',
        component: () => import('../views/team-member/neeraj-swaroop.vue'),
        meta: {
          title: 'Neeraj Swaroop - Kedaara'
        }
      },
      {
        path: 'aashray-tandon-copy',
        name: 'aashray-tandon-copy',
        component: () => import('../views/team-member/aashray-tandon-copy.vue'),
        meta: {
          title: 'J K Menon - Kedaara'
        }
      },
      {
        path: 'govindarajan-narayanan',
        name: 'govindarajan-narayanan',
        component: () => import('../views/team-member/govindarajan-narayanan.vue'),
        meta: {
          title: 'Govindarajan Narayanan - Kedaara'
        }
      },
      {
        path: 'ashwani-maheshwari',
        name: 'ashwani-maheshwari',
        component: () => import('../views/team-member/ashwani-maheshwari.vue'),
        meta: {
          title: 'Ashwani Maheshwari - Kedaara'
        }
      },
      {
        path: 'pushkaraj-shenai',
        name: 'pushkaraj-shenai',
        component: () => import('../views/team-member/pushkaraj-shenai.vue'),
        meta: {
          title: 'Pushkaraj Shenai - Kedaara'
        }
      },
      {
        path: 'rishiraj-khajanchi',
        name: 'rishiraj-khajanchi',
        component: () => import('../views/team-member/rishiraj-khajanchi.vue'),
        meta: {
          title: 'Rishiraj Khajanchi - Kedaara'
        }
      },
      {
        path: 'mani-mulki',
        name: 'mani-mulki',
        component: () => import('../views/team-member/mani-mulki.vue'),
        meta: {
          title: 'Mani Mulki - Kedaara'
        }
      },
      {
        path: 'amit-mittal',
        name: 'amit-mittal',
        component: () => import('../views/team-member/amit-mittal.vue'),
        meta: {
          title: 'Amit Mittal - Kedaara'
        }
      },
      {
        path: 'ashwin-jain',
        name: 'ashwin-jain',
        component: () => import('../views/team-member/ashwin-jain.vue'),
        meta: {
          title: 'Ashwin Jain - Kedaara'
        }
      },
      {
        path: 'avinash-unni',
        name: 'avinash-unni',
        component: () => import('../views/team-member/avinash-unni.vue'),
        meta: {
          title: 'Avinash Unni - Kedaara'
        }
      },
      {
        path: 'rohit-vyas',
        name: 'rohit-vyas',
        component: () => import('../views/team-member/rohit-vyas.vue'),
        meta: {
          title: 'Rohit Vyas - Kedaara'
        }
      },
      {
        path: 'poonam-gupta',
        name: 'poonam-gupta',
        component: () => import('../views/team-member/poonam-gupta.vue'),
        meta: {
          title: 'Poonam Gupta - Kedaara'
        }
      },
      {
        path: 'vindi-banga',
        name: 'vindi-banga',
        component: () => import('../views/team-member/vindi-banga.vue'),
        meta: {
          title: 'Vindi Banga - Kedaara'
        }
      },
      {
        path: 'tej-gujadhur',
        name: 'tej-gujadhur',
        component: () => import('../views/team-member/tej-gujadhur.vue'),
        meta: {
          title: 'Tej Gujadhur - Kedaara'
        }
      },
      {
        path: 'santosh-gujadhur',
        name: 'santosh-gujadhur',
        component: () => import('../views/team-member/santosh-gujadhur.vue'),
        meta: {
          title: 'Santosh Gujadhur - Kedaara'
        }
      },
      {
        path: 'kevin-a-smith',
        name: 'kevin-a-smith',
        component: () => import('../views/team-member/kevin-a-smith.vue'),
        meta: {
          title: 'Kevin A. Smith - Kedaara'
        }
      },
    ]
  },
  {
    path: '/cdr',
    name: 'cdr',
    component: () => import('../views/cdr.vue'),
    meta: {
      title: 'CD&R - Kedaara'
    }
  },
  {
    path: '/kedaara-capital-invests-in-dairy-day-south-indias-leading-ice-cream-brand',
    name: 'kedaara-capital-invests-in-dairy-day-south-indias-leading-ice-cream-brand',
    component: () => import('../views/kedaara-capital-invests-in-dairy-day-south-indias-leading-ice-cream-brand.vue'),
    meta: {
      title: 'kedaara capital invests in dairy day, south india’s leading ice cream brand - Kedaara'
    }
  },
  {
    path: '/kedaara-capital-invests-in-k12-techno-services',
    name: 'kedaara-capital-invests-in-k12-techno-services',
    component: () => import('../views/kedaara-capital-invests-in-k12-techno-services.vue'),
    meta: {
      title: 'Kedaara Capital invests in K12 Techno Services - Kedaara'
    }
  },
  {
    path: '/perfios-announces-229-million-series-d-funding-by-kedaara-capital',
    name: 'perfios-announces-229-million-series-d-funding-by-kedaara-capital',
    component: () => import('../views/perfios-announces-229-million-series-d-funding-by-kedaara-capital.vue'),
    meta: {
      title: 'Perfios announces $229 Million Series D Funding by Kedaara Capital - Kedaara'
    }
  },
  {
    path: '/kedaara-capital-acquires-majority-stake-in-oliva-skin-hair-clinic-a-market-leading-medico-aesthetic-dermatology-services-player-in-india',
    name: 'kedaara-capital-acquires-majority-stake-in-oliva-skin-hair-clinic-a-market-leading-medico-aesthetic-dermatology-services-player-in-india',
    component: () => import('../views/kedaara-capital-acquires-majority-stake-in-oliva-skin-hair-clinic-a-market-leading-medico-aesthetic-dermatology-services-player-in-india.vue'),
    meta: {
      title: 'KEDAARA CAPITAL ACQUIRES MAJORITY STAKE IN OLIVA SKIN HAIR CLINIC, A MARKET LEADING MEDICO-AESTHETIC DERMATOLOGY SERVICES PLAYER IN INDIA - Kedaara'
    }
  },
  {
    path: '/kedaara-capital-invests-inr-800-cr-in-avanse-financial-services',
    name: 'kedaara-capital-invests-inr-800-cr-in-avanse-financial-services',
    component: () => import('../views/kedaara-capital-invests-inr-800-cr-in-avanse-financial-services.vue'),
    meta: {
      title: 'Kedaara Capital invests INR 800 Cr. in Avanse Financial Services - Kedaara'
    }
  },
  {
    path: '/ami-lifesciences-appoints-amit-kaptain-as-chief-executive-officer',
    name: 'ami-lifesciences-appoints-amit-kaptain-as-chief-executive-officer',
    component: () => import('../views/ami-lifesciences-appoints-amit-kaptain-as-chief-executive-officer.vue'),
    meta: {
      title: 'Ami Lifesciences Appoints Amit Kaptain as Chief Executive Officer - Kedaara'
    }
  },
  {
    path: '/kedaara-capital-invests-in-oasis-fertility-an-innovator-and-one-of-the-market-leaders-in-assisted-reproductive-technology-art-industry',
    name: 'kedaara-capital-invests-in-oasis-fertility-an-innovator-and-one-of-the-market-leaders-in-assisted-reproductive-technology-art-industry',
    component: () => import('../views/kedaara-capital-invests-in-oasis-fertility-an-innovator-and-one-of-the-market-leaders-in-assisted-reproductive-technology-art-industry.vue'),
    meta: {
      title: 'Kedaara Capital invests in Oasis Fertility, an innovator and one of the market leaders in assisted reproductive technology (ART) industry - Kedaara'
    }
  },
  {
    path: '/kedaara-capital-and-general-atlantic-lead-inr-1500-crore-investment-in-asg-eye-hospitals',
    name: 'kedaara-capital-and-general-atlantic-lead-inr-1500-crore-investment-in-asg-eye-hospitals',
    component: () => import('../views/kedaara-capital-and-general-atlantic-lead-inr-1500-crore-investment-in-asg-eye-hospitals.vue'),
    meta: {
      title: 'KEDAARA CAPITAL AND GENERAL ATLANTIC LEAD INR 1,500 CRORE INVESTMENT IN ASG EYE HOSPITALS - Kedaara'
    }
  },
  {
    path: '/kedaara-acquires-majority-stake-in-gavs-technologies-an-ai-led-digital-transformation-company-focussed-on-healthcare-and-other-verticals',
    name: 'kedaara-acquires-majority-stake-in-gavs-technologies-an-ai-led-digital-transformation-company-focussed-on-healthcare-and-other-verticals',
    component: () => import('../views/kedaara-acquires-majority-stake-in-gavs-technologies-an-ai-led-digital-transformation-company-focussed-on-healthcare-and-other-verticals.vue'),
    meta: {
      title: 'Kedaara Acquires Majority Stake in GAVS Technologies, an AI-led digital transformation company focussed on healthcare and other verticals - Kedaara'
    }
  },
  {
    path: '/kedaara-acquires-majority-stake-in-gs-lab-a-leader-in-digital-product-engineering-services',
    name: 'kedaara-acquires-majority-stake-in-gs-lab-a-leader-in-digital-product-engineering-services',
    component: () => import('../views/kedaara-acquires-majority-stake-in-gs-lab-a-leader-in-digital-product-engineering-services.vue'),
    meta: {
      title: 'Kedaara acquires majority stake in GS Lab, a leader in digital product engineering services - Kedaara'
    }
  },
  {
    path: '/purplle-com-closes-funding-of-75m-led-by-kedaara-capital',
    name: 'purplle-com-closes-funding-of-75m-led-by-kedaara-capital',
    component: () => import('../views/purplle-com-closes-funding-of-75m-led-by-kedaara-capital.vue'),
    meta: {
      title: 'Purplle.com closes funding of $75M led by Kedaara Capital - Kedaara'
    }
  },
  {
    path: '/kedaara-capital-exits-its-stake-in-parksons-packaging-indias-largest-independent-folding-carton-manufacturer-through-a-sale-to-warburg-pincus',
    name: 'kedaara-capital-exits-its-stake-in-parksons-packaging-indias-largest-independent-folding-carton-manufacturer-through-a-sale-to-warburg-pincus',
    component: () => import('../views/kedaara-capital-exits-its-stake-in-parksons-packaging-indias-largest-independent-folding-carton-manufacturer-through-a-sale-to-warburg-pincus.vue'),
    meta: {
      title: 'Kedaara Capital Exits its Stake in Parksons Packaging, India’s Largest Independent Folding Carton Manufacturer, Through a Sale to Warburg Pincus - Kedaara'
    }
  },
  {
    path: '/kedaara-capital-and-the-promoter-family-jointly-exit-manjushree-technopack-indias-largest-rigid-plastic-packaging-solution-provider-through-a-secondary-sale-to-advent-international',
    name: 'kedaara-capital-and-the-promoter-family-jointly-exit-manjushree-technopack-indias-largest-rigid-plastic-packaging-solution-provider-through-a-secondary-sale-to-advent-international',
    component: () => import('../views/kedaara-capital-and-the-promoter-family-jointly-exit-manjushree-technopack-indias-largest-rigid-plastic-packaging-solution-provider-through-a-secondary-sale-to-advent-international.vue'),
    meta: {
      title: 'KEDAARA CAPITAL AND THE PROMOTER FAMILY JOINTLY EXIT MANJUSHREE TECHNOPACK, INDIA’S LARGEST RIGID PLASTIC PACKAGING SOLUTION PROVIDER, THROUGH A SECONDARY SALE TO ADVENT INTERNATIONAL - Kedaara'
    }
  },
  {
    path: '/kedaara-capital-and-partners-group-to-acquire-vishal-mega-mart-indias-premier-fashion-led-hypermarket-brand',
    name: 'kedaara-capital-and-partners-group-to-acquire-vishal-mega-mart-indias-premier-fashion-led-hypermarket-brand',
    component: () => import('../views/kedaara-capital-and-partners-group-to-acquire-vishal-mega-mart-indias-premier-fashion-led-hypermarket-brand.vue'),
    meta: {
      title: 'Kedaara Capital and Partners Group to acquire Vishal Mega Mart, India\'s premier fashion-led hypermarket brand - Kedaara'
    }
  },
  {
    path: '/kedaara-capital-invests-inr-200-crores-in-parksons-packaging',
    name: 'kedaara-capital-invests-inr-200-crores-in-parksons-packaging',
    component: () => import('../views/kedaara-capital-invests-inr-200-crores-in-parksons-packaging.vue'),
    meta: {
      title: 'Kedaara Capital invests INR 200 crores in Parksons Packaging - Kedaara'
    }
  },
  {
    path: '/kedaara-capital-announces-an-investment-in-au-financiers',
    name: 'kedaara-capital-announces-an-investment-in-au-financiers',
    component: () => import('../views/kedaara-capital-announces-an-investment-in-au-financiers.vue'),
    meta: {
      title: 'Kedaara Capital announces an investment in Au Financiers - Kedaara'
    }
  },
  {
    path: '/mahindra-logistics-receives-200-crore-private-equity-investment-from-kedaara-capital',
    name: 'mahindra-logistics-receives-200-crore-private-equity-investment-from-kedaara-capital',
    component: () => import('../views/mahindra-logistics-receives-200-crore-private-equity-investment-from-kedaara-capital.vue'),
    meta: {
      title: 'Mahindra Logistics receives 200 crore Private Equity investment from Kedaara Capital - Kedaara'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue'),
    meta: {
      title: 'Contact - Kedaara'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title
  next()
})

export default router

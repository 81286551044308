<template>
  <a class="skip-link screen-reader-text" href="#content">Skip to content</a>
  <div data-elementor-type="header" data-elementor-id="14" class="elementor elementor-14 elementor-location-header"
    data-elementor-settings="[]">
    <div class="elementor-section-wrap">
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-48438522 elementor-section-content-middle elementor-section-full_width elementor-section-height-min-height elementor-section-height-default elementor-section-items-middle elementor-invisible"
        data-id="48438522" data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;sticky&quot;:&quot;top&quot;,&quot;animation&quot;:&quot;fadeInDown&quot;,&quot;sticky_effects_offset&quot;:100,&quot;sticky_on&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;],&quot;sticky_offset&quot;:0}">
        <div class="elementor-container elementor-column-gap-default">
          <div class="elementor-row">
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3b2ee5de"
              data-id="3b2ee5de" data-element_type="column">
              <div class="elementor-column-wrap elementor-element-populated">
                <div class="elementor-widget-wrap">
                  <div class="elementor-element elementor-element-56eb7fe9 elementor-widget elementor-widget-image"
                    data-id="56eb7fe9" data-element_type="widget" data-widget_type="image.default">
                    <div class="elementor-widget-container">
                      <div class="elementor-image">
                        <a href="/">
                          <img width="163" height="78"
                            src="https://kedaara.com/wp-content/uploads/2020/06/kedaara-logo.svg"
                            class="attachment-full size-full" alt="" loading="lazy" /> </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5a4c9f33"
              data-id="5a4c9f33" data-element_type="column">
              <div class="elementor-column-wrap elementor-element-populated">
                <div class="elementor-widget-wrap">
                  <div
                    class="elementor-element elementor-element-3c30c77c elementor-nav-menu__align-right elementor-nav-menu--indicator-chevron elementor-nav-menu--dropdown-mobile elementor-nav-menu--stretch elementor-nav-menu__text-align-center elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
                    data-id="3c30c77c" data-element_type="widget"
                    data-settings="{&quot;full_width&quot;:&quot;stretch&quot;,&quot;layout&quot;:&quot;horizontal&quot;,&quot;toggle&quot;:&quot;burger&quot;}"
                    data-widget_type="nav-menu.default">
                    <div class="elementor-widget-container">
                      <nav role="navigation"
                        class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-none">
                        <ul id="menu-1-3c30c77c" class="elementor-nav-menu">
                          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-46"><a
                              href="/about-us" class="elementor-item menu-link">About Us</a></li>
                          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-107"><a
                              href="/portfolio" class="elementor-item menu-link">Portfolio</a></li>
                          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-108"><a
                              href="/team" class="elementor-item menu-link">Team</a></li>
                          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-105"><a
                              href="/cdr" class="elementor-item menu-link">CD&#038;R</a></li>
                          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2112"><a
                              href="/press-release" class="elementor-item menu-link">Press
                              Releases</a></li>
                          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-106"><a
                              href="/contact" class="elementor-item menu-link">Contact</a></li>
                        </ul>
                      </nav>
                      <div class="elementor-menu-toggle" role="button" tabindex="0" aria-label="Menu Toggle"
                        aria-expanded="false">
                        <i class="eicon-menu-bar" aria-hidden="true"></i>
                        <span class="elementor-screen-only">Menu</span>
                      </div>
                      <nav class="elementor-nav-menu--dropdown elementor-nav-menu__container" role="navigation"
                        aria-hidden="true">
                        <ul id="menu-2-3c30c77c" class="elementor-nav-menu">
                          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-46"><a
                              href="/about-us" class="elementor-item menu-link">About Us</a></li>
                          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-107"><a
                              href="/portfolio" class="elementor-item menu-link">Portfolio</a></li>
                          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-108"><a
                              href="/team" class="elementor-item menu-link">Team</a></li>
                          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-105"><a
                              href="/cdr" class="elementor-item menu-link">CD&#038;R</a></li>
                          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2112"><a
                              href="/press-release" class="elementor-item menu-link">Press
                              Releases</a></li>
                          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-106"><a
                              href="/contact" class="elementor-item menu-link">Contact</a></li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
</script>
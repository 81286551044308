var astraGetParents = function(e, t) {
        Element.prototype.matches || (Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector || Element.prototype.oMatchesSelector || Element.prototype.webkitMatchesSelector || function(e) {
            for (var t = (this.document || this.ownerDocument).querySelectorAll(e), a = t.length; 0 <= --a && t.item(a) !== this;);
            return -1 < a
        });
        for (var a = []; e && e !== document; e = e.parentNode) t ? e.matches(t) && a.push(e) : a.push(e);
        return a
    },
    getParents = function(e, t) {
        console.warn("getParents() function has been deprecated since version 2.5.0 or above of Astra Theme and will be removed in the future. Use astraGetParents() instead."), astraGetParents(e, t)
    },
    astraToggleClass = function(e, t) {
        e.classList.contains(t) ? e.classList.remove(t) : e.classList.add(t)
    },
    toggleClass = function(e, t) {
        console.warn("toggleClass() function has been deprecated since version 2.5.0 or above of Astra Theme and will be removed in the future. Use astraToggleClass() instead."), astraToggleClass(e, t)
    };
! function() {
    function e(e, t) {
        t = t || {
            bubbles: !1,
            cancelable: !1,
            detail: void 0
        };
        var a = document.createEvent("CustomEvent");
        return a.initCustomEvent(e, t.bubbles, t.cancelable, t.detail), a
    }
    "function" != typeof window.CustomEvent && (e.prototype = window.Event.prototype, window.CustomEvent = e)
}();
var astraTriggerEvent = function(e, t, a) {
    var s = new CustomEvent(t, 2 < arguments.length && void 0 !== a ? a : {});
    e.dispatchEvent(s)
};
! function() {
    function e() {
        var e = document.querySelector("body").style.overflow;
        document.querySelector("body").style.overflow = "hidden";
        var t = window.innerWidth;
        document.querySelector("body").style.overflow = e;
        var a = astra.break_point,
            s = document.querySelectorAll(".main-header-bar-wrap");
        if (0 < s.length)
            for (var n = 0; n < s.length; n++) "DIV" == s[n].tagName && s[n].classList.contains("main-header-bar-wrap") && (a < t ? (null != l[n] && l[n].classList.remove("toggled"), document.body.classList.remove("ast-header-break-point"), document.body.classList.add("ast-desktop"), astraTriggerEvent(document.body, "astra-header-responsive-enabled")) : (document.body.classList.add("ast-header-break-point"), document.body.classList.remove("ast-desktop"), astraTriggerEvent(document.body, "astra-header-responsive-disabled")))
    }
    var l = document.querySelectorAll(".main-header-menu-toggle"),
        n = {};
    e(), AstraToggleSubMenu = function() {
            var e = this.parentNode;
            if (e.classList.contains("ast-submenu-expanded") && document.querySelector("header.site-header").classList.contains("ast-menu-toggle-link") && !this.classList.contains("ast-menu-toggle")) {
                var t = e.querySelector("a").getAttribute("href");
                "" === t && "#" === t || (window.location = t)
            }
            for (var a = e.querySelectorAll(".menu-item-has-children"), s = 0; s < a.length; s++) {
                a[s].classList.remove("ast-submenu-expanded");
                var n = a[s].querySelector(".sub-menu, .children");
                null !== n && (n.style.display = "none")
            }
            var o = e.parentNode.querySelectorAll(".menu-item-has-children");
            for (s = 0; s < o.length; s++)
                if (o[s] != e) {
                    o[s].classList.remove("ast-submenu-expanded");
                    for (var r = o[s].querySelectorAll(".sub-menu"), l = 0; l < r.length; l++) r[l].style.display = "none"
                }
            e.classList.contains("menu-item-has-children") && (astraToggleClass(e, "ast-submenu-expanded"), e.classList.contains("ast-submenu-expanded") ? e.querySelector(".sub-menu").style.display = "block" : e.querySelector(".sub-menu").style.display = "none")
        }, AstraNavigationMenu = function(e) {
            console.warn("AstraNavigationMenu() function has been deprecated since version 1.6.5 or above of Astra Theme and will be removed in the future.")
        }, AstraToggleMenu = function(e) {
            if (console.warn("AstraToggleMenu() function has been deprecated since version 1.6.5 or above of Astra Theme and will be removed in the future. Use AstraToggleSubMenu() instead."), 0 < e.length)
                for (var t = 0; t < e.length; t++) e[t].addEventListener("click", AstraToggleSubMenu, !1)
        }, AstraToggleSetup = function() {
            var e = document.querySelectorAll(".main-header-bar-navigation");
            if (0 < l.length)
                for (var t = 0; t < l.length; t++)
                    if (l[t].setAttribute("data-index", t), n[t] || (n[t] = l[t], l[t].addEventListener("click", astraNavMenuToggle, !1)), void 0 !== e[t]) {
                        if (document.querySelector("header.site-header").classList.contains("ast-menu-toggle-link")) var a = e[t].querySelectorAll(".ast-header-break-point .main-header-menu .menu-item-has-children > .menu-link, .ast-header-break-point ul.main-header-menu .ast-menu-toggle");
                        else a = e[t].querySelectorAll("ul.main-header-menu .ast-menu-toggle");
                        if (0 < a.length)
                            for (var s = 0; s < a.length; s++) a[s].addEventListener("click", AstraToggleSubMenu, !1)
                    }
        }, astraNavMenuToggle = function(e) {
            e.preventDefault();
            var t = document.querySelectorAll(".main-header-bar-navigation"),
                a = this.getAttribute("data-index");
            if (void 0 === t[a]) return !1;
            for (var s = t[a].querySelectorAll(".menu-item-has-children"), n = 0; n < s.length; n++) {
                s[n].classList.remove("ast-submenu-expanded");
                for (var o = s[n].querySelectorAll(".sub-menu"), r = 0; r < o.length; r++) o[r].style.display = "none"
            } - 1 !== (this.getAttribute("class") || "").indexOf("main-header-menu-toggle") && (astraToggleClass(t[a], "toggle-on"), astraToggleClass(l[a], "toggled"), t[a].classList.contains("toggle-on") ? (t[a].style.display = "block", document.body.classList.add("ast-main-header-nav-open")) : (t[a].style.display = "", document.body.classList.remove("ast-main-header-nav-open")))
        }, document.body.addEventListener("astra-header-responsive-enabled", function() {
            var e = document.querySelectorAll(".main-header-bar-navigation");
            if (0 < e.length)
                for (var t = 0; t < e.length; t++) {
                    null != e[t] && (e[t].classList.remove("toggle-on"), e[t].style.display = "");
                    for (var a = e[t].getElementsByClassName("sub-menu"), s = 0; s < a.length; s++) a[s].style.display = "";
                    for (var n = e[t].getElementsByClassName("children"), o = 0; o < n.length; o++) n[o].style.display = "";
                    for (var r = e[t].getElementsByClassName("ast-search-menu-icon"), l = 0; l < r.length; l++) r[l].classList.remove("ast-dropdown-active"), r[l].style.display = ""
                }
        }, !1), window.addEventListener("resize", function() {
            "INPUT" !== document.activeElement.tagName && (e(), AstraToggleSetup())
        }), document.addEventListener("DOMContentLoaded", function() {
            var e, t;
            for (AstraToggleSetup(), e = document.querySelectorAll(".navigation-accessibility"), t = 0; t <= e.length - 1; t++) e[t] && s(e[t])
        }),
        function() {
            var e, t = navigator.userAgent,
                a = t.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(a[1])) e = /\brv[ :]+(\d+)/g.exec(t) || [];
            else if ("Chrome" !== a[1] || null == (e = t.match(/\bOPR|Edge\/(\d+)/))) {
                a = a[2] ? [a[1], a[2]] : [navigator.appName, navigator.appVersion, "-?"], null != (e = t.match(/version\/(\d+)/i)) && a.splice(1, 1, e[1]);
                var s = document.body;
                "Safari" === a[0] && a[1] < 11 && s.classList.add("ast-safari-browser-less-than-11")
            }
        }();
    for (var t = document.getElementsByClassName("astra-search-icon"), a = 0; a < t.length; a++) t[a].onclick = function(e) {
        if (this.classList.contains("slide-search")) {
            e.preventDefault();
            var t = this.parentNode.parentNode.parentNode.querySelector(".ast-search-menu-icon");
            t.classList.contains("ast-dropdown-active") ? ("" !== (t.querySelector(".search-field").value || "") && t.querySelector(".search-form").submit(), t.classList.remove("ast-dropdown-active")) : (t.classList.add("ast-dropdown-active"), t.querySelector(".search-field").setAttribute("autocomplete", "off"), setTimeout(function() {
                t.querySelector(".search-field").focus()
            }, 200))
        }
    };

    function s(e) {
        if (e) {
            var t = e.getElementsByTagName("button")[0];
            if (void 0 !== t || void 0 !== (t = e.getElementsByTagName("a")[0])) {
                var a = e.getElementsByTagName("ul")[0];
                if (void 0 !== a) {
                    a.setAttribute("aria-expanded", "false"), -1 === a.className.indexOf("nav-menu") && (a.className += " nav-menu"), t.onclick = function() {
                        -1 !== e.className.indexOf("toggled") ? (e.className = e.className.replace(" toggled", ""), t.setAttribute("aria-expanded", "false"), a.setAttribute("aria-expanded", "false")) : (e.className += " toggled", t.setAttribute("aria-expanded", "true"), a.setAttribute("aria-expanded", "true"))
                    };
                    for (var s = a.getElementsByTagName("a"), n = a.getElementsByTagName("ul"), o = 0, r = n.length; o < r; o++) n[o].parentNode.setAttribute("aria-haspopup", "true");
                    for (o = 0, r = s.length; o < r; o++) s[o].addEventListener("focus", c, !0), s[o].addEventListener("blur", d, !0), s[o].addEventListener("click", i, !0)
                } else t.style.display = "none"
            }
        }
    }

    function i() {
        var e = this || "";
        if (e && !e.classList.contains("astra-search-icon") && -1 !== new String(e).indexOf("#")) {
            var t = e.parentNode;
            if (!document.body.classList.contains("ast-header-break-point") || document.querySelector("header.site-header").classList.contains("ast-menu-toggle-link") && t.classList.contains("menu-item-has-children"))
                for (; - 1 === e.className.indexOf("nav-menu");) "li" === e.tagName.toLowerCase() && -1 !== e.className.indexOf("focus") && (e.className = e.className.replace(" focus", "")), e = e.parentElement;
            else {
                document.querySelector(".main-header-menu-toggle").classList.remove("toggled");
                var a = document.querySelector(".main-header-bar-navigation");
                a.classList.remove("toggle-on"), a.style.display = "none";
                var s = document.querySelector(".menu-below-header-toggle"),
                    n = document.querySelector(".ast-below-header"),
                    o = document.querySelector(".ast-below-header-actual-nav");
                s && n && o && (s.classList.remove("toggled"), n.classList.remove("toggle-on"), o.style.display = "none");
                var r = document.querySelector(".menu-above-header-toggle"),
                    l = document.querySelector(".ast-above-header"),
                    i = document.querySelector(".ast-above-header-navigation");
                r && l && i && (r.classList.remove("toggled"), l.classList.remove("toggle-on"), i.style.display = "none"), astraTriggerEvent(document.querySelector("body"), "astraMenuHashLinkClicked")
            }
        }
    }

    function c() {
        for (var e = this; - 1 === e.className.indexOf("nav-menu");) "li" === e.tagName.toLowerCase() && (-1 !== e.className.indexOf("focus") ? e.className = e.className.replace(" focus", "") : e.className += " focus"), e = e.parentElement
    }

    function d() {
        var e = this || "";
        if (-1 === new String(e).indexOf("#") || !document.body.classList.contains("ast-mouse-clicked"))
            for (; - 1 === e.className.indexOf("nav-menu");) "li" === e.tagName.toLowerCase() && (-1 !== e.className.indexOf("focus") ? e.className = e.className.replace(" focus", "") : e.className += " focus"), e = e.parentElement
    }
    if (document.body.onclick = function(e) {
            if (void 0 !== e.target.classList && !e.target.classList.contains("ast-search-menu-icon") && 0 === astraGetParents(e.target, ".ast-search-menu-icon").length && 0 === astraGetParents(e.target, ".ast-search-icon").length)
                for (var t = document.getElementsByClassName("ast-search-menu-icon"), a = 0; a < t.length; a++) t[a].classList.remove("ast-dropdown-active")
        }, "querySelector" in document && "addEventListener" in window) {
        var o = document.body;
        o.addEventListener("mousedown", function() {
            o.classList.add("ast-mouse-clicked")
        }), o.addEventListener("keydown", function() {
            o.classList.remove("ast-mouse-clicked")
        })
    }
}();
<template>
  <div
    data-elementor-type="footer"
    data-elementor-id="22"
    class="elementor elementor-22 elementor-location-footer"
    data-elementor-settings="[]"
  >
    <div class="elementor-section-wrap">
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-acefa16 elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="acefa16"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-default">
          <div class="elementor-row">
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-35e77ae"
              data-id="35e77ae"
              data-element_type="column"
            >
              <div class="elementor-column-wrap elementor-element-populated">
                <div class="elementor-widget-wrap">
                  <div
                    class="elementor-element elementor-element-f422903 elementor-widget elementor-widget-image"
                    data-id="f422903"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-image">
                        <a href="/">
                          <img
                            width="300"
                            height="300"
                            src="https://kedaara.com/wp-content/uploads/2020/06/kedaara-logo.svg"
                            class="attachment-medium size-medium"
                            alt=""
                            loading="lazy"
                            srcset="
                              https://kedaara.com/wp-content/uploads//2020/06/kedaara-logo.svg  150w,
                              https://kedaara.com/wp-content/uploads//2020/06/kedaara-logo.svg  300w,
                              https://kedaara.com/wp-content/uploads//2020/06/kedaara-logo.svg 1024w
                            "
                            sizes="(max-width: 300px) 100vw, 300px"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8aa6b12 footer-nav-links"
              data-id="8aa6b12"
              data-element_type="column"
            >
              <div class="elementor-column-wrap elementor-element-populated">
                <div class="elementor-widget-wrap">
                  <section
                    class="elementor-section elementor-inner-section elementor-element elementor-element-103a8ca elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="103a8ca"
                    data-element_type="section"
                  >
                    <div
                      class="elementor-container elementor-column-gap-default"
                    >
                      <div class="elementor-row">
                        <div
                          class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-83e72a0 footer-col"
                          data-id="83e72a0"
                          data-element_type="column"
                        >
                          <div
                            class="elementor-column-wrap elementor-element-populated"
                          >
                            <div class="elementor-widget-wrap">
                              <div
                                class="elementor-element elementor-element-c1a9cc2 elementor-widget elementor-widget-text-editor"
                                data-id="c1a9cc2"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div class="elementor-widget-container">
                                  <div
                                    class="elementor-text-editor elementor-clearfix"
                                  >
                                    <p>
                                      <a href="/">Home</a
                                      ><br /><br /><a
                                        href="/press-release"
                                        >Press Releases</a
                                      ><br /><br /><a
                                        href="/contact"
                                        >Contact Us</a
                                      >
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-9c0c341 footer-col"
                          data-id="9c0c341"
                          data-element_type="column"
                        >
                          <div
                            class="elementor-column-wrap elementor-element-populated"
                          >
                            <div class="elementor-widget-wrap">
                              <div
                                class="elementor-element elementor-element-066095b elementor-widget elementor-widget-text-editor"
                                data-id="066095b"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div class="elementor-widget-container">
                                  <div
                                    class="elementor-text-editor elementor-clearfix"
                                  >
                                    <a href="/about-us"
                                      >About Us </a
                                    ><br />
                                    <br />
                                    <a
                                      href="/teamgroup/investment-team"
                                      >Team</a
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-96c998b footer-col"
                          data-id="96c998b"
                          data-element_type="column"
                        >
                          <div
                            class="elementor-column-wrap elementor-element-populated"
                          >
                            <div class="elementor-widget-wrap">
                              <div
                                class="elementor-element elementor-element-e2ef110 elementor-widget elementor-widget-text-editor"
                                data-id="e2ef110"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div class="elementor-widget-container">
                                  <div
                                    class="elementor-text-editor elementor-clearfix"
                                  >
                                    <a
                                      href="/portfolio/fund-i"
                                      >Portfolio</a
                                    >
                                    <br /><br />
                                    <a href="/cdr"
                                      >CD&amp;R</a
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-51d82c7 elementor-hidden-desktop elementor-hidden-tablet elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="51d82c7"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-extended">
          <div class="elementor-row">
            <div
              class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-220b5e5"
              data-id="220b5e5"
              data-element_type="column"
            >
              <div class="elementor-column-wrap elementor-element-populated">
                <div class="elementor-widget-wrap">
                  <div
                    class="elementor-element elementor-element-4bae227 elementor-widget elementor-widget-image"
                    data-id="4bae227"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-image">
                        <a href="/">
                          <img
                            width="300"
                            height="300"
                            src="https://kedaara.com/wp-content/uploads/2020/06/kedaara-logo.svg"
                            class="attachment-medium size-medium"
                            alt=""
                            loading="lazy"
                            srcset="
                              https://kedaara.com/wp-content/uploads//2020/06/kedaara-logo.svg  150w,
                              https://kedaara.com/wp-content/uploads//2020/06/kedaara-logo.svg  300w,
                              https://kedaara.com/wp-content/uploads//2020/06/kedaara-logo.svg 1024w
                            "
                            sizes="(max-width: 300px) 100vw, 300px"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b116f7c footer-nav-links"
              data-id="b116f7c"
              data-element_type="column"
            >
              <div class="elementor-column-wrap elementor-element-populated">
                <div class="elementor-widget-wrap">
                  <div
                    class="elementor-element elementor-element-9c320b6 elementor-widget elementor-widget-text-editor"
                    data-id="9c320b6"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-text-editor elementor-clearfix">
                        <strong><a href="/">Home</a></strong>
                        <br /><br />
                        <strong
                          ><a href="/about-us"
                            >About Us</a
                          ></strong
                        >
                        <br /><br />
                        <strong
                          ><a
                            href="/portfolio/fund-i"
                            >Portfolio</a
                          ></strong
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-5b7dcee footer-nav-links"
              data-id="5b7dcee"
              data-element_type="column"
            >
              <div class="elementor-column-wrap elementor-element-populated">
                <div class="elementor-widget-wrap">
                  <div
                    class="elementor-element elementor-element-f8bc827 elementor-widget elementor-widget-text-editor"
                    data-id="f8bc827"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-text-editor elementor-clearfix">
                        <strong
                          ><a href="/contact"
                            >Contact Us</a
                          ></strong
                        >
                        <br /><br />
                        <strong
                          ><a
                            href="/teamgroup/investment-team"
                            >Team</a
                          ></strong
                        >
                        <br /><br />
                        <strong
                          ><a href="/cdr"
                            >CD&amp;R</a
                          ></strong
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-a47e714 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="a47e714"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-default">
          <div class="elementor-row">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fe6d507"
              data-id="fe6d507"
              data-element_type="column"
            >
              <div class="elementor-column-wrap elementor-element-populated">
                <div class="elementor-widget-wrap">
                  <div
                    class="elementor-element elementor-element-0aeca9a kedaara-copyright-text elementor-widget elementor-widget-text-editor"
                    data-id="0aeca9a"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-text-editor elementor-clearfix">
                        <p style="text-align: center">
                          © 2024 Kedaara Capital Investment Managers Ltd
                        </p>
                        <p style="text-align: center">All rights reserved</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
</style>